import React from "react";
const defaultState = {
  isTouched: false,
  lastActivity: new Date(),
  products: []
};

const ShopContext = React.createContext(defaultState);

class ShopProvider extends React.Component {
  state = defaultState;

  componentDidMount() {
    const cart = JSON.parse(localStorage.getItem("cart"));
    if (cart) {
      this.setState({ ...cart, isTouched: cart.products.length > 0 });
    }
  }

  cleanUpCart = cart => {
    return {
      ...cart,
      products: cart.products
        .filter(x => x.quantity > 0)
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })
    };
  };

  onChange = (id, name, sku, price, quantity) => {
    let newCart = { ...this.state };
    newCart.lastActivity = new Date();
    const oldProduct = this.state.products.find(x => x.id === id);
    if (oldProduct) {
      // Change quantity
      const newProduct = { ...oldProduct };
      newProduct.quantity = quantity;
      newCart.products = newCart.products.filter(x => x.id !== id);
      newCart.products.push(newProduct);
    } else if (!oldProduct) {
      // New to cart
      newCart.products.push({ id, name, sku, price, quantity });
    }
    newCart = this.cleanUpCart(newCart);
    this.setState(newCart, () => {
      localStorage.setItem("cart", JSON.stringify(newCart));
    });
  };

  onEmpty = () => {
    let newCart = { ...this.state, lastActivity: new Date(), products: [] };
    this.setState(newCart);
    localStorage.removeItem("cart");
  };

  onTouched = () => {
    this.setState({ isTouched: true });
  };

  onBuy = () => {
    const baseUrl = window.location.origin;
    const items = this.state.products.map(product => ({
      sku: product.sku,
      quantity: product.quantity
    }));
    const successUrl = `${baseUrl}/danke`;
    const cancelUrl = `${baseUrl}/sorten`;

    window
      .Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
      .redirectToCheckout({
        items,
        successUrl,
        cancelUrl,
        billingAddressCollection: "required"
      })
      .then(function(result) {
        console.log(result);
      });
  };

  render() {
    const { children } = this.props;
    return (
      <ShopContext.Provider
        value={{
          products: this.state.products,
          lastActivity: this.state.lastActivity,
          isTouched: this.state.isTouched,
          onChange: this.onChange,
          onTouched: this.onTouched,
          onEmpty: this.onEmpty,
          onBuy: this.onBuy
        }}
      >
        {children}
      </ShopContext.Provider>
    );
  }
}
export default ShopContext;
export { ShopProvider };
