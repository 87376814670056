// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-danke-js": () => import("./../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mhd-js": () => import("./../src/pages/mhd.js" /* webpackChunkName: "component---src-pages-mhd-js" */),
  "component---src-pages-newsletter-abgemeldet-js": () => import("./../src/pages/newsletter/abgemeldet.js" /* webpackChunkName: "component---src-pages-newsletter-abgemeldet-js" */),
  "component---src-pages-newsletter-aktualisiert-js": () => import("./../src/pages/newsletter/aktualisiert.js" /* webpackChunkName: "component---src-pages-newsletter-aktualisiert-js" */),
  "component---src-pages-newsletter-angemeldet-js": () => import("./../src/pages/newsletter/angemeldet.js" /* webpackChunkName: "component---src-pages-newsletter-angemeldet-js" */),
  "component---src-pages-sorten-js": () => import("./../src/pages/sorten.js" /* webpackChunkName: "component---src-pages-sorten-js" */)
}

